exports.components = {
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=default" /* webpackChunkName: "component---src-templates-page-tsx" */)
}



exports.head = {
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=head" /* webpackChunkName: "component---src-templates-page-tsxhead" */)
}

